import { graphql } from "gatsby";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";

const Contact = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "left",
          isFluid: true,
          button: "account", // cta, account, null
        }}
        footerConfig={{
          style: "style2",
          theme: "light",
        }}
      >
        <Header />
        <div className="inner-banner pt-29 pb-md-11 bg-default-2">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9 heading-color">
                    <Trans>contact.title</Trans>
                  </h2>
                  <p className="gr-text-8 mb-13 text-color-opacity">
                    <Trans>contact.title-text</Trans>
                  </p>
                </div>
              </Col>
              <Col
                lg="6"
                xl="6"
                md="6"
                sm="12"
                className="text-center sm:text-right pr-5 mb-6 sm:mb-0"
              >
                <a
                  href="https://trustify.tawk.help/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="secondary" className="mt-0">
                    <Trans>contact.button-2</Trans>
                  </Button>
                </a>
              </Col>
              <Col
                lg="6"
                xl="6"
                md="6"
                sm="12"
                className="text-center sm:text-left pl-5 mb-6 sm:mb-0"
              >
                <a href="mailto:info@trustify.ch">
                  <Button variant="secondary" className="mt-0">
                    <Trans>contact.button</Trans>
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pb-5 pb-md-33 bg-default-2 ">
          <Container>
            <Row className="justify-content-center">
              <Col xs="12">
                <div className="login-form bg-white border-gray-3 px-8 pt-8 pb-9 px-sm-11 py-sm-11 shadow-1 rounded-10">
                  <ContactForm />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </PageWrapper>
    </>
  );
};
export default Contact;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
