import { withFormik } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import * as Yup from "yup";

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "The name must be at least two characters long")
    .max(100, "The name can be a maximum of 100 characters")
    .required("Please provide your name"),
  email: Yup.string()
    .email("The given email is not valid")
    .required("Please provide your email"),
  phone: Yup.string()
    .min(5, "The phone must be at least five characters long")
    .max(15, "The name can be a maximum of 15 characters")
    .optional(),
  subject: Yup.string()
    .min(2, "The subject must be at least two characters long")
    .max(100, "The subject can be a maximum of 100 characters")
    .required("Please provide a subject"),
  message: Yup.string()
    .min(5, "The message must be at least five characters long")
    .max(2000, "The message can be a maximum of 5000 characters")
    .required("Please provide a message"),
});

const Form = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    submitting,
    status,
  } = props;
  return (
    <>
      {status?.success && (
        <div className="alert alert-success">
          <p className="mb-0">
            <Trans>contact.form-success</Trans>
          </p>
        </div>
      )}
      {status?.error && (
        <div className="alert alert-danger">
          <p className="mb-0">
            <Trans>contact.form-error</Trans>
          </p>
        </div>
      )}
      {!status?.error && !status?.success && (
        <>
          {submitting ? (
            <div className="text-center">
              <i className="fa fa-cog fa-spin fa-2x" />
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md="6">
                  <div className="form-group mb-7">
                    <label
                      htmlFor="name"
                      className="gr-text-11 fw-bold text-blackish-blue"
                    >
                      <Trans>contact.form-name</Trans>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control gr-text-11 border mt-3 bg-white"
                      placeholder="John Doe"
                    />
                    {errors.name && touched.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group mb-7">
                    <label
                      htmlFor="email"
                      className="gr-text-11 fw-bold text-blackish-blue"
                    >
                      <Trans>contact.form-mail</Trans>
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control gr-text-11 border mt-3 bg-white"
                      placeholder="john@mail.com"
                    />
                    {errors.email && touched.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group mb-7">
                    <label
                      htmlFor="phone"
                      className="gr-text-11 fw-bold text-blackish-blue"
                    >
                      <Trans>contact.form-phone</Trans>
                    </label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control gr-text-11 border mt-3 bg-white"
                      placeholder="+41 23 456 78 90 (optional)"
                    />
                    {errors.phone && touched.phone && (
                      <div className="text-danger">{errors.phone}</div>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group mb-7">
                    <label
                      htmlFor="subject"
                      className="gr-text-11 fw-bold text-blackish-blue"
                    >
                      <Trans>contact.form-subject</Trans>
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control gr-text-11 border mt-3 bg-white"
                      placeholder="Hi"
                    />
                    {errors.subject && touched.subject && (
                      <div className="text-danger">{errors.subject}</div>
                    )}
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group mb-7">
                    <label
                      htmlFor="message"
                      className="gr-text-11 fw-bold text-blackish-blue"
                    >
                      <Trans>contact.form-message</Trans>
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control gr-text-11 border-gray-3 gr-textarea-height mt-3 bg-white"
                    ></textarea>
                    {errors.message && touched.message && (
                      <div className="text-danger">{errors.message}</div>
                    )}
                  </div>
                </Col>
                <Col xs="3">
                  <div className="form-group button-block mt-3 ">
                    <Button
                      className="form-btn btn btn-primary w-100"
                      type="submit"
                    >
                      <Trans>contact.form-send</Trans>
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          )}
        </>
      )}
    </>
  );
};

const ContactForm = withFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  }),
  validationSchema: ContactSchema,
  handleSubmit: (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    fetch("https://trustify-landing-mail.support5597.workers.dev/send", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: values.name,
        email: values.email,
        phone: values.phone,
        subject: values.subject,
        message: values.message,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          setStatus({ error: true });
        } else {
          setStatus({ success: true });
          setSubmitting(false);
        }
      })
      .catch((err) => setStatus({ error: true }));
  },
})(Form);

export default ContactForm;
